<template>
  <div>
    <div class="choose-border"/>
    <div class="d-flex justify-content-between">
      <div class="prk-pcg-fz-14 font-weight-bold pcg-the-darkest-gray title">
        {{ $t(`components.prk_syllabus.new_element.${type}.title`) }}
      </div>
      <i class="pcg-icon-cross close" @click="$emit('close')"/>
    </div>
    <pcg-text-input
        :label="$t(`components.prk_syllabus.new_element.${type}.text_above`)"
        :value="$attrs.descriptionAbove"
        type='textarea'
        @input="$emit('update:descriptionAbove', $event)"
    />

    <div v-if="type !== 'table'">
      <pcg-text-input
          class="name"
          :label="$t(`components.prk_syllabus.new_element.${type}.name`)"
          :value="$attrs.label"
          @input="$emit('update:label', $event)"
      />
    </div>

    <div v-else-if="type ==='table'">
      <b-row
          class="prk-fz-12 font-weight-bold
                 pcg-the-darkest-gray-color d-flex
                 align-items-baseline pb-4">
        <b-col cols="auto" class="center-text">
          {{ $t('components.prk_syllabus.new_element.table.columns_number') }}
        </b-col>
        <b-col>
          <prk-main-select
              :default-option="defaultOption"
              class="select" color="aqua"
              :options="numberOfColumns"
              @input="generateTableStructure($event)"
          />
        </b-col>
      </b-row>

      <b-row class="headings">
        <b-col v-for="(_, key, index) in $attrs.value.value[0]" :key="index">
          <pcg-text-input
              v-model="$attrs.value.value[0][key]"
              :label="$t('components.prk_syllabus.new_element.table.heading')"
              class="name"
          />
        </b-col>
      </b-row>
    </div>

    <pcg-text-input
        :label="$t(`components.prk_syllabus.new_element.${type}.text_below`)"
        :value="$attrs.descriptionUnder"
        type='textarea'
        @input="$emit('update:descriptionUnder', $event)"
    />
  </div>
</template>

<script>
import PrkMainSelect from '../../../PrkSelect'

export default {
  name: 'PrkSectionNewElement',
  components: { PrkMainSelect },
  data () {
    return {
      numberOfColumns: [
        {
          text: '2',
          value: 2,
        },
        {
          text: '3',
          value: 3,
        },
        {
          text: '4',
          value: 4,
        },
        {
          text: '5',
          value: 5,
        },
        {
          text: '6',
          value: 6,
        },
        {
          text: '7',
          value: 7,
        },
        {
          text: '8',
          value: 8,
        },
      ],
      selectedNumberOfColumns: 2,
    }
  },
  computed: {
    type () {
      return this.$attrs.elementType
    },
    defaultOption () {
      return this.numberOfColumns
        .find(el => el.value === this.selectedNumberOfColumns)
    },
  },
  created () {
    if (this.type === 'table') {
      const numberOfColumns = Object.keys(this.$attrs.value.value[0]).length
      this.selectedNumberOfColumns = numberOfColumns || 2
    }
  },
  methods: {
    chooseElement (index) {
      this.selectedNumberOfColumns = this.numberOfColumns[index].value
    },
    generateTableStructure (numberOfColumns) {
      const labels = {}
      const tableStructure = {}

      for (let i = 0; i < numberOfColumns; i++) {
        labels[`label${i}`] = Object.values(this.$attrs.value.value[0])[i] || ''
        tableStructure[`value${i}`] = ''
      }
      const tableData = []
      tableData.push(labels, tableStructure)
      this.$emit('update:value', { value: tableData })
      this.selectedNumberOfColumns = numberOfColumns
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

.title {
  margin-bottom: 1.928rem;
  letter-spacing: 0.36px;
}

.name {
  max-width: 27.071rem;
}

.close {
  cursor: pointer;
  font-size: 0.76rem;
}

::v-deep {
  .pcg-textarea {
    height: 35px;
  }

  .form-group {
    margin-bottom: 1.4rem;
  }

  .filter-option-inner {
    color: $main-active-color;
  }

  .btn {
    padding: 0 1.429rem;
  }
}

.center-text {
  line-height: 3rem;
}

.headings {
  padding: .86rem 1.857rem 0;
  background-color: $pcg-lightest-gray;
  margin: 0 -2.3rem 2rem;
}

.choose-border {
  margin: 2rem -2.2rem 0;
  border-top: 1px solid $pcg-lightest-gray;
  padding-top: 1.285rem;
}

.select {
  max-width: 6rem;

  ::v-deep .dropdown-toggle {
    font-size: 1rem !important;
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  ::v-deep {
    .filter-option-inner {
      color: $main-active-color;
    }
  }

  .headings {
    background-color: $pcg-lightest-gray;
  }

  .choose-border {
    border-top: 1px solid $pcg-lightest-gray;
  }
}
</style>
