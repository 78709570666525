import { reactive, onBeforeMount } from '@vue/composition-api'
import Vue from 'vue'

export default function (root) {
  const filters = reactive({ academic_year: `${new Date().getFullYear()}/${new Date().getFullYear() + 1}` })

  const updateFilters = (value, field) => {
    if (field === 'faculty_id') {
      delete filters.course_name_id
      delete filters.type_id
      delete filters.kind_id
      delete filters.speciality_id
    }

    if (field === 'course_name_id') {
      delete filters.type_id
      delete filters.kind_id
      delete filters.speciality_id
    }

    if (field === 'type_id') {
      delete filters.kind_id
      delete filters.speciality_id
    }

    if (field === 'kind_id') {
      delete filters.speciality_id
    }

    Vue.set(filters, field, value)

    root.$router.push({
      path: root.$route.path,
      query: root.$_.pickBy(filters, root.$_.identity),
    }).catch(() => {})
  }

  onBeforeMount(() => {
    for (const param in root.$route.query) {
      Vue.set(filters, param, root.$route.query[param])
    }
  })

  return {
    filters,
    updateFilters,
  }
}
