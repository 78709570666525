<template>
  <div class="pcg-table-container prk-table">
    <div v-if="showHeadline" class="prk-table-headline">
      <slot name="headline">
        <table-headline :index="headlineIndex" :syllabus-header="syllabusHeader" :show-headline-index="showHeadlineIndex">
          <slot name="headline-text"/>
        </table-headline>
      </slot>
    </div>

    <slot name="sub-heading">
      <sub-heading v-if="showSubHeading">
        <slot name="subheading-text"/>
      </sub-heading>
    </slot>

    <!-- Main table -->
    <div v-if="items" class="pcg-table-content">
      <b-table
          :items="items"
          :fields="fields"
          borderless
          class="pcg-table"
          thead-tr-class="pcg-table-header-row"
          :tbody-tr-class="tbodyTrClass"
          :tfoot-tr-class="tfootTrClass"
          :responsive="responsive"
          :tbody-transition-props="disableTransition ? {} : { name: 'c-fade' }"
          :primary-key="primaryKey"
          v-bind="$attrs"
          v-on="$listeners"
      >
        <template
            v-for="(header) in fields"
            v-slot:[`cell(${header.key})`]="data"
        >
          <slot name="content" v-bind:header="header" v-bind:data="data">
            {{ data.item[header.key] }}
          </slot>
        </template>
        <template v-for="(_, slot) of tableSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope"/>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import TableHeadline from './TableHelpers/PrkTableHeadline'
import SubHeading from './TableHelpers/PrkSubHeading'

export default {
  name: 'PrkTable',
  components: {
    TableHeadline,
    SubHeading,
  },
  props: {
    customFields: {
      type: Array,
      default: () => ([]),
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
    disableTransition: {
      type: Boolean,
      default: false,
    },
    excludedFields: {
      type: Array,
      default: () => (['type', 'selected', 'id', '__type']),
    },
    fieldsProps: {
      type: Object,
      default: () => ({}),
    },
    headlineIndex: {
      type: String,
      default: '0',
    },
    items: {
      type: Array,
      default: null,
    },
    responsive: {
      type: Boolean,
      default: true,
    },
    rowsType: {
      type: String,
      default: 'narrow',
      validator (type) {
        return ['narrow', 'wide'].includes(type)
      },
    },
    showHeadline: {
      type: Boolean,
      default: true,
    },
    showSubHeading: {
      type: Boolean,
      default: false,
    },
    primaryKey: {
      type: String,
      default: 'id',
    },
    disableAutoHeaders: {
      type: Boolean,
      default: false,
    },
    headersProps: {
      type: Array,
      default: () => ([]),
    },
    syllabusHeader: {
      type: Boolean,
      default: false,
    },
    showHeadlineIndex: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headers () {
      if (this.items[0]) {
        return Object.keys(this.items[0])
          .filter(key => !this.excludedFields.includes(key) && key[0] !== '_')
      } else {
        return []
      }
    },
    fields () {
      const headers = this.disableAutoHeaders ? this.headersProps : this.headers
      const fields = headers.map((header) => {
        return {
          key: header,
          label: this.label(header),
          ...this.fieldsProps,
        }
      })
      !this.disableActions && fields.push({
        key: 'actions',
        label: '',
        ...this.fieldsProps,
      })
      !!this.customFields.length && fields.push(...this.customFields)
      return fields
    },
    tfootTrClass () {
      return this.rowsType === 'narrow' ? this.customClass ? 'pcg-table-narrow-row-alternative' : 'pcg-table-narrow-row' : 'pcg-table-wide-row'
    },
    tableSlots () {
      const { header, ...slots } = this.$scopedSlots
      return slots
    },
  },
  methods: {
    label (header) {
      const key = `components.prk_syllabus.table.${this.$_.snakeCase(header)}`
      if (this.$te(key)) {
        return this.$t(key)
      } else {
        return header
      }
    },
    tbodyTrClass (item, _) {
      const classes = []
      classes.push(this.tfootTrClass)
      if (item?.selected) classes.push('pcg-main-active-color')
      return classes
    },
  },
}
</script>

<style scoped lang="scss">
@import "app/javascript/prk/assets/stylesheets/vars.scss";

table {
  margin: 0;
}

::v-deep {
  &.prk-table.pcg-table-container {
    background: $pcg-white;
    box-shadow: none;
    margin-bottom: 0;
    min-width: initial;

    .pcg-table-content {
      padding: 0;

      .table-responsive {
        margin-bottom: 0;
      }

      .pcg-table-header-row {
        color: $pcg-blue;

        th {
          padding-top: 1.5rem;
        }

        th:not([aria-colindex="1"]) {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }

        th:last-child {
          padding-right: 2.14rem;
        }
      }

      tbody {
        .pcg-table-narrow-row {
          td {
            vertical-align: middle;
            padding-top: 1rem;
            padding-bottom: 1rem;
          }

          td:not([aria-colindex="1"]) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }

          td:last-child {
            padding-right: 2.14rem;
          }
        }

        .pcg-table-narrow-row:last-child {
          td {
            border-bottom: none;
          }
        }

        .pcg-table-narrow-row-alternative {
          td {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }

          td:first-child {
            padding-left: 2.14rem;
          }

          td:not([aria-colindex="1"]) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }

          td:last-child {
            padding-right: 2.14rem;
          }
        }

        .pcg-table-narrow-row-alternative:last-child {
          td {
            border-bottom: none;
          }
        }
      }

      tbody {
        .b-table-details.pcg-table-narrow-row {
          td:first-child {
            padding: 0;
          }

          td:last-child, td:first-child {
            border-bottom: 1px solid $pcg-lightest-gray;
          }
        }
        .b-table-details.pcg-table-narrow-row-alternative {
          td:first-child {
            padding: 0;
          }

          td:last-child, td:first-child {
            border-bottom: 1px solid $pcg-lightest-gray;
          }
        }
      }

      tfoot {
        border-top: 1px solid $pcg-lightest-gray;
      }
    }
  }
}

.prk-table-headline {
  font-weight: 500;
  font-size: 1.14em;
  letter-spacing: .02rem;
  padding: 1rem 2.14rem 0;
  width: 100%;
  color: $main-color;
  background-color: $pcg-white;
}
</style>

<style lang="scss" scoped>
@import '../assets/stylesheets/vars-contrast';

.contrast {
  ::v-deep {
    &.prk-table.pcg-table-container {
      background: $pcg-white;
      .pcg-table-content {
        .pcg-table-header-row {
          color: $pcg-blue;
        }
        tbody {
          td:last-child, td:first-child {
            border-bottom: 1px solid $pcg-lightest-gray;
          }
        }
        tfoot {
          border-top: 1px solid $pcg-lightest-gray;
        }
      }
    }
  }

  .prk-table-headline {
    color: $main-color;
    background-color: $pcg-white;
  }
}
</style>
