<template>
  <pcg-modal
      modal-id="course-effects-modal"
      size="xl"
      :title="title"
      icon="fas fa-plus"
      :btn-title="$t('general.save')"
      :action="() => { action(selectedEffects) }"
      :hide-footer="syllabusList"
      @hide="clear"
  >
    <div v-if="!syllabusList" class="mb-2 subheader">
      {{ $t('components.prk_modals.course_effects.select_all') }}
    </div>
    <b-row align-v="center">
      <course-effects-modal-select-all
          v-if="!syllabusList"
          :selected-all="selectedAll"
          :select-effects="selectEffects"
      />

      <b-col>
        <prk-search :query.sync="query"/>
      </b-col>

      <b-col class="d-flex" :class="justifyContent">
        <categories
            :args="{ emit: true }"
            :categories="categories"
            @update:category-id="categoryId = $event"
        />
      </b-col>
    </b-row>

    <prk-table
        :show-headline="false"
        :items="effects"
        :excluded-fields="EXCLUDED_FIELDS"
        :fields-props="{ tdClass: tdClass }"
        class="mt-3"
    >
      <template v-slot:table-colgroup="{ fields }">
        <prk-colgroup :fields="fields" width="50%" :columns="['description', 'enDescription']"/>
      </template>

      <template v-slot:head(id)>
        <pcg-checkbox :value="selectedAll" @input="selectEffects"/>
      </template>

      <template #cell(id)="{ item: effect }">
        <pcg-checkbox v-model="effect.selected"/>
      </template>

      <template #cell(description)="{ item: effect }">
        <prk-animated-description :description="effect.description"/>
      </template>

      <template #cell(enDescription)="{ item: effect }">
        <prk-animated-description :description="effect.enDescription"/>
      </template>

      <template #cell(actions)="data">
        <base-arrow
            :show="data.item.toggleDetails"
            @click="showText(data, $event)"
        />
      </template>
    </prk-table>
  </pcg-modal>
</template>

<script>
import PrkTable from '../../PrkTable'
import PrkAnimatedDescription from '../../PrkAnimatedDescription'
import Categories from '../../shared_view/Categories'
import PrkColgroup from '../../PrkColGroup'
import CourseEffectsModalSelectAll from './course_effects_modal/CourseEffectsModalSelectAll'
import PrkSearch from '../../PrkSearch'
import useExpandText from '../../../composables/useExpandText'

export default {
  name: 'CourseEffectsModal',
  setup () {
    const {
      tdClass,
      showText,
    } = useExpandText('prk-course-effects-modal')
    return { showText, tdClass }
  },
  components: {
    CourseEffectsModalSelectAll,
    PrkColgroup,
    Categories,
    PrkAnimatedDescription,
    PrkTable,
    PrkSearch,
  },
  props: {
    action: { type: Function, default: () => {} },
    syllabusId: { type: String, default: '' },
    excludedEffectsIds: { type: Array, default: () => ([]) },
  },
  jsonapi: {
    categories: {
      config: { method: 'get', url: 'categories' },
      update (categories) {
        return categories.map(({ name, id }) => ({ id, text: name }))
      },
    },
    courseEffects: {
      config: ({ courseEffectsUrl }) => ({
        method: 'get',
        url: courseEffectsUrl,
        params: {
          serializer: 'course_effects_modal',
        },
      }),
      variables () {
        return {
          courseEffectsUrl: this.courseEffectsUrl,
        }
      },
      skip () {
        return !this.courseEffectsUrl
      },
      update (courseEffects) {
        return this.mapEffects(courseEffects)
      },
    },
    standardEffects: {
      config: ({ courseId }) => ({
        method: 'get',
        url: `standard_effects?course_id=${courseId}`,
        params: {
          serializer: 'course_effects_modal',
        },
      }),
      skip () {
        return !this.courseId.length
      },
      variables () {
        return {
          courseId: this.courseId,
        }
      },
      update (standardEffects) {
        return this.mapEffects(standardEffects)
      },
    },
  },
  data () {
    return {
      categoryId: '',
      categories: [],
      courseEffects: [],
      courseEffectsUrl: '',
      standardEffects: [],
      query: '',
    }
  },
  computed: {
    selectedEffects () {
      return this.allEffects.filter(effect => effect.selected)
    },
    selectedAll () {
      return this.selectedEffects.length === this.effects.length &&
          this.effects.length !== 0
    },
    courseId () {
      return this.$route.query.courseId || ''
    },
    allEffects () {
      if (!this.excludedEffectsIds) return []
      return this.courseEffects.concat(this.standardEffects)
        .filter(effect => !this.excludedEffectsIds.includes(effect.id))
        .filter(effect => {
          return effect.symbol.toLowerCase().includes(this.query.toLowerCase()) ||
                effect.description.toLowerCase().includes(this.query.toLowerCase()) ||
                effect.enDescription.toLowerCase().includes(this.query.toLowerCase())
        }).sort((a, b) => a.symbol.localeCompare(b.symbol, undefined, { numeric: true, sensitivity: 'base' }))
    },
    effects () {
      if (!this.excludedEffectsIds) return []
      return this.courseEffects.concat(this.standardEffects)
        .filter(effect => !this.excludedEffectsIds.includes(effect.id))
        .filter(effect => effect.categoryId.match(this.categoryId))
        .filter(effect => {
          return effect.symbol.toLowerCase().includes(this.query.toLowerCase()) ||
              effect.description.toLowerCase().includes(this.query.toLowerCase()) ||
              effect.enDescription.toLowerCase().includes(this.query.toLowerCase())
        }).sort((a, b) => a.symbol.localeCompare(b.symbol, undefined, { numeric: true, sensitivity: 'base' }))
    },
    syllabusList () {
      return !!this.syllabusId.length
    },
    title () {
      const suffix = this.syllabusList ? 'raw_text' : 'name'
      return this.$t(`components.prk_modals.course_effects.${suffix}`)
    },
    justifyContent () {
      return {
        'justify-content-start': this.syllabusList,
        'justify-content-end': !this.syllabusList,
      }
    },
  },
  watch: {
    syllabusList () {
      this.syllabusList && this.EXCLUDED_FIELDS.push('id')
    },
    syllabusId () {
      this.courseEffectsUrl = `course_effects?syllabus_id=${this.syllabusId}`
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['selected', 'toggleDetails', 'categoryId']
    if (this.$checkRouteName('matrix_edit_path')) {
      this.courseEffectsUrl = `course_effects?course_id=${this.courseId}`
    }
  },
  methods: {
    clear () {
      this.effects.forEach(effect => (effect.selected = false))
    },
    selectEffects (selected) {
      selected ? this.$selectAll(this.effects) : this.$unselectAll(this.effects)
    },
    mapEffects (effects) {
      return effects.map(effect => {
        return {
          id: effect.id,
          symbol: effect.symbol,
          description: effect.description,
          enDescription: effect.enDescription,
          ...this.$_.pick(effect, 'category', 'categoryId'),
          category: effect.categoryName,
          selected: false,
          toggleDetails: false,
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

.subheader {
  color: $pcg-the-darkest-gray;
  font-weight: bold;
}

.label {
  color: $pcg-dark-gray;
  font-weight: bold;
}

@media only screen and (max-width: 998px) {
  .pcg-slide-tab-btn {
    padding: 0 .86rem !important;
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .subheader {
    color: $pcg-the-darkest-gray;
  }

  .label {
    color: $pcg-dark-gray;
  }
}
</style>
