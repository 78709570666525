export const general = {
  academic_year: 'Rok akademicki | Roki akademickie',
  accept: 'Akceptuj',
  accepted: 'Zatwierdzony',
  accept_event: 'Akceptuj wydarzenie',
  accept_offer: 'Akceptuj ofertę',
  accept_employer: 'Akceptuj pracodawcę',
  accuracy: 'trafność',
  active: 'Aktywna | Aktywny',
  action_cannot_be_reverted: 'Ta akcja nie może zostać cofnięta!',
  add: 'Dodaj',
  additional_section: 'Dodatkowa sekcja',
  add_attachment: 'Dodaj załącznik',
  add_category: 'Dodaj kategorię',
  add_characteristic: 'Dodaj charakterystykę',
  add_course: 'Dodaj kierunek',
  add_course_effect: 'Dodaj efekt kierunkowy',
  add_discipline: 'Dodaj dyscyplinę',
  add_effect: 'Dodaj efekt | Dodaj efekty',
  add_effect_of_general_characteristics: 'Dodaj efekt charakterystyk ogólnych',
  add_event: 'Dodaj wydarzenie',
  add_en_description: 'Dodaj opis po angielsku',
  add_faculty: 'Dodaj wydział',
  add_field: 'Dodaj dziedzinę',
  add_form_of_class: 'Dodaj formę zajęć',
  add_form_of_credit: 'Dodaj formę zaliczenia',
  add_general_characteristics: 'Dodaj charakterystyki ogólne',
  add_general_effects: 'Dodaj efekt ogólny | Dodaj efekty ogólne',
  add_kind: 'Nowa forma',
  add_literature: 'Dodaj literature',
  add_new_subject: 'Dodaj nowy przedmiot',
  add_path: 'Dodaj ścieżkę',
  add_profile: 'Dodaj profil',
  add_semester: 'Dodaj semestr',
  add_teaching_effects: 'Dodaj efekty nauczycielskie',
  add_speciality: 'Dodaj specjalność',
  add_standard: 'Dodaj standard',
  add_standard_effect: 'Dodaj efekt standardowy',
  add_subject_name: 'Dodaj nazwę przedmiotu',
  add_subject_to_plan: 'Dodaj przedmiot do planu',
  add_type: 'Dodaj poziom',
  add_offer: 'Dodaj ofertę',
  add_value: 'Dodaj wartość',
  all: 'Wszyscy',
  answer: 'Odpowiedź',
  apply: 'Zastosuj',
  apprenticeship: 'Praktyki',
  archive: 'Archiwizuj',
  areas: 'Obszar | Obszary',
  are_you_sure: 'Jesteś pewien?',
  assign_effects_of_general_characteristics: 'Przypisz efekty charakterystyk ogólnych',
  assign_general_effects: 'Przypisz efekty ogólne',
  author: 'Autor',
  automatic_download: 'Pobieranie automatyczne',
  back: 'Powrót',
  back_to_edit: 'Wróć do edycji',
  basic_literature: 'Literatura podstawowa',
  be_completed_by_didactic: 'Do uzupełnienia przez dydaktyka',
  cancel: 'Anuluj',
  career_office_team: 'Zespół BK',
  category: 'Kategoria',
  change: 'Zmień',
  change_history: 'Historia zmian',
  change_order: 'Zmień kolejność',
  channel: 'Kanał',
  check_box: 'Checkbox',
  check_errors: 'Sprawdź błędy',
  choose: 'Wybierz',
  choose_study_plan: 'Wybierz plan studiów',
  clear: 'Wyczyść',
  clo: {
    default: 'Kierunkowe efekty uczenia (KEU)',
    list: 'Lista dostępnych KEU',
  },
  close: 'Zamknij',
  close_survey: 'Zamknij ankietę',
  collapse: 'Zwiń',
  collapse_all: 'Zwiń wszystkie',
  company: 'Firma',
  competence_deleted: 'Kompetencja została usunięta',
  competence_updated: 'Kompetencja została zaktualizowana pomyślnie',
  competences: 'Kompetencje',
  confirm: 'Zatwierdź',
  confirm_and_add_subject: 'Zatwierdź i dodaj przedmiot',
  content: 'Treść',
  coordinator: 'Osoba koordynująca',
  coordinating_person: 'Osoba koordynująca',
  copy: 'Kopiuj',
  copy_subject_effects: 'Skopiuj efekty przedmiotowe',
  copy_syllabus: 'Skopiuj sylabus',
  course: 'Kierunek | Kierunki',
  course_effect: 'Efekt kierunkowy | Efekty kierunkowe',
  course_name: 'Nazwa kierunku',
  course_not_found: 'Nie znaleziono pasującego kierunku',
  course_of_study: 'Tok studiów',
  currency: {
    pln: 'zł',
  },
  current_version: 'Aktualna wersja',
  create_new: 'Stwórz nową',
  create_study_plan: 'Stwórz plan studiów',
  date_added: 'data dodania',
  date_from: 'Data od',
  date_of_publication: 'data publikacji',
  date_to: 'Data do',
  days_left: '| Został 1 dzień do końca | Zostały {count} dni do końca | Zostało {count} dni do końca',
  delete: 'Usuń',
  delete_didactic: 'Usuń dydaktyka',
  delete_element: 'Usuń element | Usuń elementy',
  delete_message: 'Usuń wiadomość',
  departments: 'Wydział | Wydziały',
  description: 'Opis',
  destroy_survey: 'Usuń ankietę',
  dictionary_updated: 'Słownik został zaktualizowany pomyślnie',
  dictionary_word_updated: 'Słowo słownikowe zostało zaktualizowane pomyślnie',
  discard_changes: 'Odrzuć zmiany',
  discipline: 'Dyscyplina | Dyscypliny',
  download: 'Pobierz',
  download_doc: 'Pobierz plik DOC',
  download_excel: 'Pobierz plik EXCEL',
  download_pdf: 'Pobierz plik PDF',
  download_course_effects: 'Pobierz efekty kierukowe',
  download_effects_check_report: 'Pobierz raport kontrolny efektów',
  download_general_effects: 'Wydruk efektów ogólnych dla kierunku',
  ects: 'ECTS',
  edit: 'Edytuj',
  edit_category: 'Edytuj kategorię',
  edit_course: 'Edytuj kierunek',
  edit_course_effect: 'Edytuj efekt kierunkowy',
  edit_discipline: 'Edytuj dyscyplinę',
  edit_effect: 'Edytuj efekt',
  edit_faculty: 'Edytuj wydział',
  edit_field: 'Edytuj dziedzinę',
  edit_form_of_class: 'Edytuj formę zajęć',
  edit_form_of_credit: 'Edytuj formę zaliczenia',
  edit_kind: 'Edytuj formę',
  edit_literature: 'Edytuj literaturę',
  edit_profile: 'Edytuj profil',
  edit_speciality: 'Edytuj specjalność',
  edit_study_plan: 'Edytuj plan studiów',
  edit_subject: 'Edytuj przedmiot',
  edit_subject_name: 'Edytuj nazwę przedmiotu',
  edit_type: 'Edytuj poziom',
  educator: 'Dydaktyk | Dydaktycy',
  educator_0: 'Osoba odpowiedzialna',
  educator_1: 'Osoba koordynująca',
  educator_2: 'Osoba nadzorująca',
  effects: 'Efekty',
  end_date: 'Data zakończenia',
  errors: {
    already_taken: 'To pole zostało już zajęte',
    greater_0: 'Musi być większe od 0',
    required: 'To pole jest wymagane',
  },
  error_try_later: 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.',
  event: 'Wydarzenie',
  event_type: {
    fair: 'Targi',
    training: 'Szkolenie',
    rally: 'Wiec',
    other: 'Inne',
  },
  events: {
    statuses: {
      choose: 'Wybierz',
      during_fill: 'W trakcie wypełniania',
      template: 'Szablon',
      accepted: 'Zatwierdzony',
      draft: 'Wersja robocza',
      finished: 'Zakończone',
      for_acceptance: 'Oczekujące',
      recent: 'Nowe',
      rejected: 'Odrzucony',
      published: 'Opublikowane',
      to_fill: 'Do wypełnienia',
      to_improve: 'Do poprawy',
      to_verification: 'Do weryfikacji',
    },
  },
  exam: 'Egzamin',
  exercises: 'Ćwiczenia',
  expand: 'Rozwiń',
  expand_all: 'Rozwiń wszystkie',
  export: 'Ekportuj',
  export_courses_effects: 'Pobierz efekty kierunkowe',
  export_subjects_effects: 'Pobierz efekty przedmiotowe',
  faculty: 'Wydział | Wydziały',
  faculty_name: 'Nazwa wydziału',
  file_upload: 'Przeciągnij i upuśc lub wybierz plik',
  field: 'Dziedzina | Dziedziny',
  field_of_study: 'Kierunek',
  finish: 'Zakończ',
  finish_event: 'Zakończ wydarzenie',
  finish_offer: 'Zakończ ofertę',
  forbidden: 'Nie masz uprawnień do wykonania tej czynności.',
  forms: 'Forma | Formy',
  form_of_class: 'Forma zajęć | Formy zajęć',
  form_of_credit: 'Forma zaliczenia | Formy zaliczenia',
  from: 'od',
  full_time: 'Pełny etat',
  gallery: 'Galeria',
  general_characteristics: 'Charakterystyki ogólne',
  general_effects: 'Efekty ogólne',
  generally_academic: 'Ogólnoakademicki',
  generate_syllabuses: 'Generuj sylabus | Generuj sylabusy',
  heading: 'Nagłówek',
  hello_world: 'Siema Świecie!',
  hide: 'Ukryj',
  hide_on_printout: 'Ukryte na wydruku',
  hide_section: 'Ukryj sekcję',
  in: 'w',
  inactive: 'Nieaktywna | Nieaktywny',
  import_plan: 'Importuj plan | Importuj plany',
  job: 'Praca',
  kind: 'Forma | Formy',
  kind_name: 'Forma',
  knowledge: 'Wiedza',
  lack: 'Brak',
  leading: 'Prowadzący',
  leading_discipline_id: 'Dyscyplina wiodąca',
  lecture: 'Wykład',
  lecturers: 'Prowadzący',
  levels: 'Poziom | Poziomy',
  literatures: 'Literatury',
  supplementary_literature: 'Literatura uzupełniająca',
  localization: 'Lokalizacja',
  logged_out: 'Zostałeś wylogowany',
  make_copy: 'Stwórz kopię',
  make_offer: 'Złóż ofertę',
  matrix_saved: 'Matryca została zapisana',
  matrix_subject_names: 'Przedmioty z matrycy',
  message_sent: 'Wiadomość została wysłana',
  message_type: {
    communique: 'Komunikat',
    newsletter: 'Newsletter',
    sms: 'SMS',
    comment: 'Komentarz',
  },
  modify_statuses: 'Modyfikuj statusy',
  must_be_greater_than_or_equal_to_0: 'musi być większe lub równe 0',
  name: 'Nazwa',
  name_of_subject: 'Nazwa przedmiotu',
  name_of_study_plan: 'Nazwa toku',
  name_and_surname: 'Imie i nazwisko',
  name_of_template: 'Nazwa szablonu',
  new_course: 'Nowy kierunek',
  new_course_effect: 'Nowy efekt kierunkowy',
  new_effect: 'Nowy efekt',
  new_faculty: 'Nowy wydział',
  new_field: 'Nowa dziedzina',
  new_form_of_class: 'Nowa forma zajęć',
  new_form_of_credit: 'Nowa forma zaliczenia',
  new_kind: 'Nowa forma',
  new_literature: 'Nowa literatura',
  new_profile: 'Nowy profil',
  new_speciality: 'Nowa specjalność',
  new_subject_name: 'Nowa nazwa przedmiotu',
  new_type: 'Nowy poziom',
  new_message: 'Nowa wiadomość',
  new_plan: 'Nowy plan',
  new_template: 'Nowy szablon',
  new_standard: 'Nowy standard',
  new_standard_effect: 'Nowy efekt standardowy',
  new_subject: 'Nowy przedmiot',
  new_study_plan: 'Nowy plan studiów',
  no: 'Nie',
  no_access: 'Brak dostępu',
  noname: 'Brak nazwy',
  not_number: 'Nie jest liczbą',
  number: 'Numer',
  number_of_hours: 'Liczba godzin',
  number_of_terms: 'Liczba semestrów',
  objective_effects: 'Efekty przedmiotowe',
  on_off: 'Wł/Wył',
  offer: 'Oferta',
  offer_type: {
    job: 'Praca',
    practice: 'Praktyki',
    training: 'Staż',
  },
  offers: {
    statuses: {
      accepted: 'Zaakceptowana',
      draft: 'Wersja robocza',
      finished: 'Zakończona',
      for_acceptance: 'Oczekująca',
      recent: 'Nowa',
      rejected: 'Odrzucona',
      published: 'Opublikowana',
    },
  },
  ok: 'Ok',
  or: 'lub',
  other: 'Inne',
  overwrite: 'Nadpisz',
  publishing_house: 'Wydawnictwo',
  part_time: 'Pół etatu',
  percent: 'Procent',
  pin_to_study_plan: 'Przypnij do planu studiów',
  plan_name: 'Nazwa planu',
  place_of_publication: 'Miejsce publikacji',
  practice: 'Praktyka',
  preview: 'Podgląd',
  preview_saved_draft: 'Podgląd zapisanej wersji roboczej',
  previous_version: 'Poprzednia wersja',
  print: 'Drukuj',
  prk: 'Prk',
  profile: 'Profil',
  process: 'Tok',
  publish: 'Opublikuj',
  publish_survey: 'Opublikuj ankietę',
  range: 'Zakres',
  recipient_type: {
    employers: 'Pracodawcy',
    students: 'Studenci',
    graduates: 'Absolwenci',
  },
  recipients_group: 'Grupa odbiorców',
  record_saved: 'Rekord został zapisany',
  register: 'Zapisz się',
  reject: 'Odrzuć',
  rejected: 'Odrzucony',
  reject_changes: 'Odrzuć zmiany',
  reject_event: 'Odrzuć wydarzenie',
  reject_offer: 'Odrzuć ofertę',
  remote_work: 'Praca zdalna',
  remove: 'Usuń',
  remove_form_of_classes: 'Usuń formę zajęć',
  remove_from_favorites: 'Usuń z ulubionych',
  remove_selected: 'Usuń zaznaczone',
  remove_term: 'Usuń semestr',
  responsible_person: 'Osoba odpowiedzialna',
  replica: 'Kopia',
  role: 'Rola',
  role_in_syllabus: 'Rola w sylabusie',
  roles: {
    admin: 'aministrator',
    administrator: 'administrator',
    career_office: 'biuro karier',
    deanery: 'dziekanat',
    educator: 'dydaktyk',
    employer: 'pracodawca',
    graduate: 'absolwent',
    student: 'student',
    supervisor: 'osoba nazdorująca',
  },
  save: 'Zapisz',
  save_changes: 'Zapisz zmiany',
  save_course: 'Zapisz kierunek',
  save_draft: 'Zapisz wersję roboczą',
  search: 'Szukaj',
  search_word: 'Wyszukaj słowo...',
  see_less: 'pokaż mniej',
  see_more: 'zobacz więcej',
  see_subjects: 'Zobacz przedmioty',
  select: 'Wybierz',
  select_all: 'Zaznacz wszystkie',
  select_city: 'Wybierz miasto',
  selected_effects: 'Wybrane efekty',
  selected_methods: 'Metody wybrane',
  selected_roles: 'Role wybrane',
  send: 'Wyślij',
  send_for_publication: 'Wyślij do publikacji',
  semester_name: 'Nazwa semestru',
  semester_type: 'Typ semestru',
  shortcut: 'Skrót',
  shortcutSubjectEffect: 'Skrót EP',
  show: 'Pokaż',
  show_all: 'Zobacz wszystkie',
  show_all_subjects: 'Pokaż wszystkie przedmioty',
  show_changes: 'Pokaż zmiany',
  show_more: 'ZOBACZ WIĘCEJ',
  show_params: 'Pokaż parametry',
  show_report: 'Pokaż raport',
  show_results: 'Pokaż wyniki',
  skills: 'Umiejętności',
  skip: 'Pomiń',
  specialization: 'Specjalizacja',
  specialization_name: 'Specjalizacja',
  speciality: 'Specjalność',
  speciality_name: 'Specjalność',
  special_kind: 'Tok',
  subject_form: 'Forma przedmiotu | Formy przedmiotu',
  standard_effect: 'Efekt standardowy | Efekty standardowe',
  standard_matrix: 'Matryca standardowa',
  standardName: 'Nazwa standardu',
  start_date: 'Data rozpoczęcia',
  status: 'Status | Statusy',
  statuses: {
    choose: 'Wybierz',
    during_fill: 'W trakcie wypełniania',
    to_fill: 'Do wypełnienia',
    to_improve: 'Do poprawy',
    to_verification: 'Do weryfikacji',
    accepted: 'Zatwierdzony',
  },
  steps: {
    next: 'Dalej',
    previous: 'Wstecz',
    publish: 'Zapisz i opublikuj',
    send_for_publication: 'Zapisz i wyślij do publikacji',
  },
  study_plan: 'Plan studiów',
  study_plan_type: 'Typ',
  study_profile: 'Profil | Profile',
  subjects: 'Przedmiot | Przedmioty',
  subject_code: 'Kod przedmiotu',
  subject_effects: 'Efekt przedmiotowy | Efekty przedmiotowe',
  subject_name: 'Nazwa przedmiotu',
  suggest: 'Zaproponuj',
  sum: 'Suma',
  summer: 'Letni',
  supervisor: 'Osoba nadzorująca',
  survey: 'Ankieta',
  surveys: {
    attributes: {
      anonymous: 'Anonimowa',
      graduate_fate: 'Badanie losów absolwentów',
      requires_answer: 'Wymaga odpowiedzi',
    },
    statuses: {
      draft: 'Kopia robocza',
      published: 'Opublikowana',
      completed: 'Zakończona',
      closed: 'Zamknięta',
    },
  },
  switch: 'Wł | Wył',
  symbol: 'Symbol',
  syllabuses: 'Sylabus | Sylabusy',
  syllabus_saved: 'Sylabus został zapisany',
  synchronize: 'Synchronizuj z planem studiów',
  teaching_effects: 'Efekty nauczycielskie',
  template: 'Szablon',
  template_name: 'Nazwa szablonu',
  text_section: 'Sekcja tekstowa',
  time_to_end: 'czas do końca',
  title: 'Tytuł',
  term: 'Semestr | Semestry',
  training: 'Staż',
  to: 'do',
  type: 'Poziom',
  type_name: 'Poziom',
  type_of_term: 'Typ semestru',
  teaching_effects_off: 'Efekty nauczycielskie są wyłączone',
  fellow: 'Typ',
  unexpected_error: 'Wystąpił nieoczekiwany błąd.',
  university_syllabus: 'Sylabus Uniwersytecki',
  university_wide_matrix: 'Matryca ogólnouczelniana',
  unregister: 'Wypisz się',
  update: 'Aktualizuj',
  update_plan: 'Aktualizuj plan',
  update_plans: 'Aktualizuj plany',
  when: 'kiedy',
  where: 'gdzie',
  winter: 'Zimowy',
  words: 'Słowa',
  work_on_site: 'Praca na miejscu',
  yes: 'Tak',
  year: 'Rok',
  year_and_term: 'Rok i semestr studiów',
  yearbook_of_course_learning_outcomes: 'Rocznik KEU',
}
