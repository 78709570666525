<template>
  <b-row class="mt-4">
    <b-col>
      <pcg-text-input
          v-model="description"
          type="textarea"
          :label="$t('general.description')"
          :error-text="$t('general.errors.required')"
          :show-error="showError"
      >
      </pcg-text-input>
    </b-col>
  </b-row>
</template>
<script>

export default {
  name: 'description-effect',
  props: {
    showError: Boolean,
  },
  computed: {
    description: {
      set: _.debounce(function (description) {
        this.$store.dispatch('courseLearningOutcome/setDescription', description)
      }, 500),
      get () {
        return this.$store.state.courseLearningOutcome.keu.effectContentObj
      },
    },
  },
}
</script>

<style lang="scss">
::v-deep {
  .pcg-textarea {
    height: 300px !important;
  }
}
</style>
