<template>
  <div class="description-container pb-3">
    <pcg-text-input :value="$attrs.description"
                    @input="$emit('update:description', $event)"
                    type='textarea'
                    :label="$t('components.prk_syllabus.section.description')"/>
  </div>
</template>
<script>
export default {
  name: 'PrkDescription',
}
</script>
<style scoped lang="scss">
.description-container {
  margin-top: 1.8rem;
}
</style>
