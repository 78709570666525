<template>
  <prk-table :headline-index="section.number"
             :syllabus-header="true"
             :show-headline-index="false"
             :items="preview ? didacticMethods : getDidacticMethods"
             :excluded-fields="['formOfClassId', 'id']"
             thead-class="d-none"
             disable-actions
  >
    <template #headline-text>
      <div class="d-flex align-items-center">
        {{ section.header }}
        <comment-modal-button
            modal-id="commentModal"
            :comment-count="section.commentCount"
            @input="setComments(section, 'SyllabusSection')"
        />
      </div>
    </template>

    <template v-slot:content="{header, data}">
      <div v-if="header.key === 'formOfClass'" class="prk-fz-12 font-weight-bolder">
        {{ data.item.formOfClass }}
      </div>
      <div v-else-if="header.key === 'text'">
        <pcg-text-input
            v-if="fillContent && editMode"
            type="textarea"
            :show-label="false"
            :value="data.item.text"
            @input="updateDidacticMethodText({
                          text: $event,
                          formOfClassId: data.item.formOfClassId
                        })"
        />
        <div v-else>
          {{ data.item.text }}
        </div>
      </div>
    </template>
  </prk-table>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PrkTable from '../../../PrkTable'
import CommentModalButton from '../../../PrkCommentModalButton'
import useComments from '../../../../composables/useComments'
import { inject } from '@vue/composition-api'
import { fillStatus } from '../../../../mixins/syllabus/status'

const { mapGetters, mapActions } = createNamespacedHelpers('syllabusFill')

export default {
  name: 'DidacticMethodsContent',
  setup (props, { root }) {
    const status = inject('status')
    const preview = inject('preview', false)
    const didacticMethods = inject('didacticMethods', [])
    const editMode = inject('editMode', true)
    const { setComments } = useComments(root, props)
    return {
      editMode,
      preview,
      status,
      didacticMethods,
      setComments,
    }
  },
  components: { PrkTable, CommentModalButton },
  mixins: [fillStatus],
  props: {
    section: Object,
  },
  computed: {
    ...mapGetters(['getDidacticMethods']),
  },
  methods: {
    ...mapActions(['updateDidacticMethodText']),
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .pcg-textarea {
    height: 54px;
  }
}
</style>
