<template>
  <prk-table
      v-if="item.value.value.length > 0"
      :show-headline="false"
      :items="item.value.value.slice(1, item.value.length)"
      :show-footer="true"
      :custom-class="true"
  >
    <template v-slot:table-colgroup="{ fields }">
      <col v-for="field in fields" :key="field.key"
           :style="{ width: field.key === 'actions' ? '5%' : '' }"
      >
    </template>
    <template v-for="(label, key, index) in item.value.value[0]" v-slot:[`head(value${index})`]
    >
      <span :key="index">{{ label || `${$t('general.heading')} ${index + 1}` }}</span>
    </template>
    <template v-slot:content="{ header, data }">
      <textarea-autosize
          v-if="header.key !== 'actions' && !basic"
          :min-height="30"
          v-model="data.item[header.key]"
          class="text-area-autosize form-control pcg-field"
      />
      <div v-else-if="header.key !== 'actions' && basic">
        {{ data.item[header.key] }}
      </div>
      <base-button-remove
          v-else-if="data.index > 0 && status !== 'accepted' && editMode"
          text="delete_row"
          @click="removeRow(data.index + 1, item.value.value)"
      />
    </template>
    <template v-if="fillContent && editMode" v-slot:custom-foot>
      <add-row :items="item.value.value" @addRow="$emit('addRow', $event)"/>
    </template>
  </prk-table>
</template>

<script>
import PrkTable from '../../PrkTable'
import AddRow from '../../TableHelpers/PrkAddRow'
import useList from '../../../composables/useList'
import { inject } from '@vue/composition-api'
import { fillStatus } from '../../../mixins/syllabus/status'

export default {
  name: 'SyllabusTable',
  setup (props, { root }) {
    const status = inject('status', 'template')
    const editMode = inject('editMode', true)
    const { removeRow } = useList(root)
    return {
      editMode,
      removeRow,
      status,
    }
  },
  components: {
    PrkTable,
    AddRow,
  },
  mixins: [fillStatus],
  props: {
    basic: {
      type: Boolean,
      default: false,
    },
    item: {
      required: true,
      type: Object,
    },
  },
  watch: {
    'item.value' (newVal) {
      if (newVal.length === 1) this.item.value.value = []
    },
  },
}
</script>

<style scoped lang="scss">
@import "app/javascript/prk/assets/stylesheets/vars.scss";

.text-area-autosize {
  width: 100%;
  border-radius: 5px;
  padding: .8rem;
}
</style>
