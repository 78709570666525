<template>
  <div>
    <return path-name="dictionaries_standards_path">
      {{ $t('components.prk_dictionaries.return') }}
    </return>
    <standard-fields
        :academic-year.sync="standard.academicYear"
        :academic-year-error="$v.standard.academicYear.$error"
        :course-name-id.sync="standard.courseNameId"
        :course-name-error="$v.standard.courseNameId.$error"
        :name.sync="standard.name"
        :name-error="$v.standard.name.$error"
        :already-taken="errors.hasOwnProperty('name')"
        @clear-error="clearNameError"
    />
    <div class="mt-4">
      <b-tabs class="mb-5">
        <b-tab :title="$tc('general.standard_effect', 2)" @click="kek = true">
          <standard-effects
              :course-effects="standard.courseEffects"
              @add:course-effect="addStandardEffect($event)"
              @update:course-effect="updateStandardEffect($event)"
              @remove:course-effect="removeStandardEffect($event)"
          />
        </b-tab>
      </b-tabs>
    </div>

    <base-beam>
      <dictionaries-beam
          :action="save" :kek="kek"
          :selected-standard-effects="selectedStandardEffects.length"
          :edit-standard="isEditRoute('standard_edit_path')"
          @remove:standard-effects="removeStandardEffects(selectedStandardEffects)"
      />
    </base-beam>
  </div>
</template>

<script>
import StandardFields from '../../../components/dictionaries/standards/StandardFields'
import DictionariesBeam from '../../../components/dictionaries/DictionariesBeam'
import StandardEffects from '../../../components/shared_view/CourseEffects'
import Return from '../../../components/base_components/Return'
import useCourses from '../../../composables/useCourses'
import useHelpers from '../../../composables/useHelpers'
import { provide } from '@vue/composition-api'
import { required } from 'vuelidate/lib/validators'
import { app } from '../../../index'

export default {
  name: 'DictionaryStandardForm',
  setup (props, { root }) {
    const {
      addCourseEffect: addStandardEffect,
      course: standard,
      saveCourse: saveStandard,
      errors,
      getCourse: getStandard,
      removeCourseEffect: removeStandardEffect,
      removeCourseEffects: removeStandardEffects,
      updateCourseEffect: updateStandardEffect,
    } = useCourses(root)

    provide('isStandardView', true)

    const { isEditRoute } = useHelpers(root)
    return {
      addStandardEffect,
      errors,
      getStandard,
      isEditRoute,
      removeStandardEffect,
      removeStandardEffects,
      saveStandard,
      standard,
      updateStandardEffect,
    }
  },
  validations: {
    standard: {
      name: {
        required,
        isUnique () {
          return !Object.hasOwnProperty.call(this.errors, 'name')
        },
      },
      academicYear: { required },
      courseNameId: { required },
    },
  },
  components: {
    StandardFields,
    DictionariesBeam,
    StandardEffects,
    Return,
  },
  data () {
    return {
      kek: false,
    }
  },
  computed: {
    saveMethod () {
      return this.isEditRoute() ? 'put' : 'post'
    },
    selectedStandardEffects () {
      return this.standard.courseEffects.filter(standardEffect => standardEffect.selected)
    },
    url () {
      const url = `standards/${this.$route.params.id}`
      return this.isEditRoute() ? url : 'standards'
    },
  },
  async created () {
    if (this.$route.params.id) {
      await this.getStandard(`standards/${this.$route.params.id}`)
    }
  },
  mounted () {
    this.link = document.querySelector('[href="/dictionaries/standards"]')
    this.link.classList.add('active', 'link-active')
  },
  methods: {
    clearNameError () {
      this.errors = {}
      this.$v.$reset()
    },
    async save () {
      const $v = this.$v
      const isSaveStandard = await this.saveStandard(
        this.url,
        this.saveMethod,
        {
          $v,
          resource: 'standard',
        })
      if (isSaveStandard) {
        await this.$router.push({
          name: 'dictionaries_standards_path',
        })
      }
    },
    // TODO powtórzenie w CourseForm i StudyPlanForm
    setHeader () {
      let subtitleKey = ''
      if (this.isEditRoute()) {
        subtitleKey = 'views.header.dictionary.edit_standard'
      } else {
        subtitleKey = 'views.header.dictionary.new_standard'
      }
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$t(subtitleKey),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    const role = localStorage.getItem('prkCR')
    if (role === 'admin') {
      next(vm => {
        vm.setHeader()
      })
    } else {
      app.$toastr.e(app.$t('general.forbidden'))
    }
  },
  beforeRouteLeave (to, from, next) {
    this.link.classList.remove('active', 'link-active')
    next()
  },
}
</script>
