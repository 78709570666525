<template>
  <div class="courses-list">
    <prk-list-filter
        filters-type="course"
        @update:filters="updateFilters"
    />
    <courses-table
        class=" mb-5"
        :filters="filters"
        @update:course-id="courseId = $event"
    />
    <prk-new-course-modal :current-year="filters.academic_year"/>

  </div>
</template>

<script>
import PrkNewCourseModal from '../../components/courses/modal/PrkNewCourseModal'
import PrkListFilter from '../../components/PrkListFilter'
import CoursesTable from '../../components/courses/CoursesTable'
import useFilters from '../../composables/useFilters'

export default {
  name: 'CoursesList',
  setup (_, { root }) {
    const { filters, updateFilters } = useFilters(root)
    return { filters, updateFilters }
  },
  components: {
    CoursesTable,
    PrkListFilter,
    PrkNewCourseModal,
  },
  data () {
    return {
      courseId: '',
    }
  },
  created () {
    const academicYear = this.$route.query.academic_year || this.filters.academic_year
    this.$router.push({
      path: this.$route.path,
      query: { academic_year: academicYear, ...this.$_.omit(this.filters, 'academic_year') },
    }).catch(() => {})
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.courses.title'),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },

}
</script>
